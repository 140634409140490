import React, { createContext, useState, useContext, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { localeMap, supportedLocales } from '../utils/helpers'
import { getLoginUrl, initializeApi } from '../../api/ncpApiMethods'

const LocaleContext = createContext({
    locale: 'en-us',
    setLocale: () => { }
})

export const LocaleProvider = ({ children }) => {
    const location = useLocation()
    const navigate = useNavigate()
    const [locale, setLocale] = useState(() => {
        const pathLocale = window.location.pathname.split('/')[3]
        return pathLocale || 'en-us'
    })
    const [ncpApiInitialized, setNcpApiInitialized] = useState(false)
    const [contextPath, setContextPath] = useState('/ncpweb')
    const [brand, setBrand] = useState('')
    const [brandId, setBrandId] = useState('')
    const [loading, setLoading] = useState(true)

    const getQueryParam = (param) => {
        const urlParams = new URLSearchParams(window.location.search)
        return urlParams.get(param)
    }

    useEffect(() => {
        const initialize = async () => {
            try {
                await initializeApi()
                setNcpApiInitialized(true)
            } catch (error) {
                console.error('Failed to initialize API', error)
            }
        }
        initialize()
    }, [])

    useEffect(() => {
        const currentBrandId = getQueryParam('brand_id')
        if (currentBrandId && currentBrandId !== brandId) {
            setBrandId(currentBrandId)
            localStorage.setItem('lastBrandId', currentBrandId)
            setLoading(false)
        }
    }, [location.search, brandId])

    useEffect(() => {
        const redirectToLogin = async () => {
            try {
                const response = await getLoginUrl(brandId)
                if (window.location.href !== response.login_url) {
                    window.location.href = response.login_url
                }
            } catch (error) {
                console.error('Error redirecting user:', error)
            } finally {
                setLoading(false)
            }
        }
        if (brandId && ncpApiInitialized) {
            redirectToLogin()
        }
    }, [brandId, ncpApiInitialized])


    useEffect(() => {
        const contextPathValue = window.contextPath
        setContextPath(contextPathValue)

        const storageData = JSON.parse(localStorage.getItem('appData')) || {}
        storageData.brand = window.brand?.toLowerCase()

        localStorage.setItem('appData', JSON.stringify(storageData))

        setBrand(storageData.brand || window.brand?.toLowerCase())
    }, [brand, contextPath])


    useEffect(() => {
        const queryLocaleId = getQueryParam('locale_id')
        const mappedLocale = queryLocaleId ? localeMap[queryLocaleId] : null
        const urlLocale = location.pathname.split('/')[3]
        const appData = JSON.parse(localStorage.getItem('appData'))
        const currentBrand = appData ? appData.brand : null
        let effectiveLocale = urlLocale
        // Prioritize locale_id if it's valid and supported
        if (mappedLocale && currentBrand && supportedLocales[currentBrand].includes(mappedLocale)) {
            effectiveLocale = mappedLocale;
        } else if (!supportedLocales[currentBrand]?.includes(urlLocale)) {
            // If URL locale is not supported, fallback to default
            effectiveLocale = supportedLocales[currentBrand][0] || 'en-us';
        }

        // Update the locale state if different from current
        if (locale !== effectiveLocale) {
            setLocale(effectiveLocale)
            navigate(`${contextPath}/login/${effectiveLocale}`)
        }
        setLoading(false)
    }, [location, locale, navigate, contextPath, brand])


    return (
        <LocaleContext.Provider value={{ locale, setLocale, brand, contextPath, ncpApiInitialized, loading }}>
            {children}
        </LocaleContext.Provider>
    )
}

export const useLocale = () => useContext(LocaleContext)

export default LocaleContext
